export const CATEGORY_ICON_MAP = {
  activity: 'dumbbell',
  people: 'smiley',
  nature: 'nature',
  food: 'food',
  travel: 'car',
  objects: 'object',
  symbols: 'heart',
  flags: 'flag',
};

export const EMOJIS_PER_ROW = 9;
export const EMOJI_ROW_HEIGHT = 34;
export const CATEGORY_ROW_HEIGHT = 37;
